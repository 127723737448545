/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.home-page .template-layout {
  max-width: 100%;
}

.hp-banner-container.full-background-wave_purple {
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #9d5190), color-stop(60%, #9d5190), color-stop(0, white), color-stop(70%, white));
  background: linear-gradient(to bottom, #9d5190 0, #9d5190 60%, white 0, white 70%);
}

@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container.full-background-wave_purple.white {
    background: #fff;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container.full-background-wave_purple {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #9d5190), color-stop(28%, #9d5190), color-stop(0, white), color-stop(70%, white));
    background: linear-gradient(to top, #9d5190 0, #9d5190 28%, white 0, white 70%);
    padding-top: 0;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .hp-banner-container.full-background-wave_purple {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #9d5190), color-stop(30%, #9d5190), color-stop(0, white), color-stop(70%, white));
    background: linear-gradient(to top, #9d5190 0, #9d5190 30%, white 0, white 70%);
  }
}
@media print, screen and (max-width: 29.99875em) {
  .hp-banner-container.full-background-wave_purple {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #9d5190), color-stop(40%, #9d5190), color-stop(0, white), color-stop(70%, white));
    background: linear-gradient(to top, #9d5190 0, #9d5190 40%, white 0, white 70%);
  }
}
@media screen and (min-width: 48em) {
  .hp-banner-container.full-background-wave_purple .hp-banner .content__button {
    background-color: #fff;
    color: #9d5190;
  }
  .hp-banner-container.full-background-wave_purple .hp-banner .content__button:hover {
    color: #9d5190;
    background-color: #fff;
  }
  .hp-banner-container.full-background-wave_purple .hp-banner .content__button.border-white {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
  }
  .hp-banner-container.full-background-wave_purple .hp-banner .content__button.border-white:hover {
    color: #9d5190;
    background-color: #EFDBEB;
    border: 1px solid #EFF4F2;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container.full-background-wave_purple .hp-banner .content__button {
    background-color: #9d5190;
    border: 0;
    color: #fff;
  }
  .hp-banner-container.full-background-wave_purple .hp-banner .content__button.border-transparent {
    border: 1px solid #9d5190;
    color: #9d5190;
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .hp-banner-container.full-background-wave_purple .hp-banner .content__button.border-transparent:hover {
    background-color: #006078;
    color: #fff;
  }
  .hp-banner-container.full-background-wave_purple .hp-banner .content__button:hover {
    color: #9d5190;
    background-color: #EFDBEB;
    border: 1px solid #9d5190;
  }
}
.hp-banner-container.full-background-wave_yellow {
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #feca02), color-stop(60%, #feca02), color-stop(0, white), color-stop(70%, white));
  background: linear-gradient(to bottom, #feca02 0, #feca02 60%, white 0, white 70%);
}

@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container.full-background-wave_yellow.white {
    background: #fff;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container.full-background-wave_yellow {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #feca02), color-stop(28%, #feca02), color-stop(0, white), color-stop(70%, white));
    background: linear-gradient(to top, #feca02 0, #feca02 28%, white 0, white 70%);
    padding-top: 0;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .hp-banner-container.full-background-wave_yellow {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #feca02), color-stop(30%, #feca02), color-stop(0, white), color-stop(70%, white));
    background: linear-gradient(to top, #feca02 0, #feca02 30%, white 0, white 70%);
  }
}
@media print, screen and (max-width: 29.99875em) {
  .hp-banner-container.full-background-wave_yellow {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #feca02), color-stop(40%, #feca02), color-stop(0, white), color-stop(70%, white));
    background: linear-gradient(to top, #feca02 0, #feca02 40%, white 0, white 70%);
  }
}
@media screen and (min-width: 48em) {
  .hp-banner-container.full-background-wave_yellow .hp-banner .content__button {
    background-color: #fff;
    color: var(--primary-color);
  }
  .hp-banner-container.full-background-wave_yellow .hp-banner .content__button:hover {
    color: var(--primary-color);
    background-color: #fff;
  }
  .hp-banner-container.full-background-wave_yellow .hp-banner .content__button.border-white {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
  }
  .hp-banner-container.full-background-wave_yellow .hp-banner .content__button.border-white:hover {
    color: #006661;
    background-color: #E8F5F3;
    border: 1px solid #EFF4F2;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container.full-background-wave_yellow .hp-banner .content__button {
    background-color: #006661;
    border: 0;
    color: #fff;
  }
  .hp-banner-container.full-background-wave_yellow .hp-banner .content__button.border-transparent {
    border: 1px solid #006661;
    color: #006661;
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .hp-banner-container.full-background-wave_yellow .hp-banner .content__button.border-transparent:hover {
    background-color: #006078;
    color: #fff;
  }
  .hp-banner-container.full-background-wave_yellow .hp-banner .content__button:hover {
    color: #006661;
    background-color: #E8F5F3;
    border: 1px solid #006661;
  }
}
.hp-banner-container.full-background-wave {
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #006661), color-stop(60%, #006661), color-stop(0, white), color-stop(70%, white));
  background: linear-gradient(to bottom, #006661 0, #006661 60%, white 0, white 70%);
}

@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container.full-background-wave.white {
    background: #fff;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container.full-background-wave {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #006661), color-stop(28%, #006661), color-stop(0, white), color-stop(70%, white));
    background: linear-gradient(to top, #006661 0, #006661 28%, white 0, white 70%);
    padding-top: 0;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .hp-banner-container.full-background-wave {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #006661), color-stop(30%, #006661), color-stop(0, white), color-stop(70%, white));
    background: linear-gradient(to top, #006661 0, #006661 30%, white 0, white 70%);
  }
}
@media print, screen and (max-width: 29.99875em) {
  .hp-banner-container.full-background-wave {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #006661), color-stop(40%, #006661), color-stop(0, white), color-stop(70%, white));
    background: linear-gradient(to top, #006661 0, #006661 40%, white 0, white 70%);
  }
}
@media screen and (min-width: 48em) {
  .hp-banner-container.full-background-wave .hp-banner .content__button {
    background-color: #fff;
    color: var(--primary-color);
  }
  .hp-banner-container.full-background-wave .hp-banner .content__button:hover {
    color: var(--primary-color);
    background-color: #fff;
  }
  .hp-banner-container.full-background-wave .hp-banner .content__button.border-white {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
  }
  .hp-banner-container.full-background-wave .hp-banner .content__button.border-white:hover {
    color: #006661;
    background-color: #E8F5F3;
    border: 1px solid #EFF4F2;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container.full-background-wave .hp-banner .content__button {
    background-color: #006661;
    border: 0;
    color: #fff;
  }
  .hp-banner-container.full-background-wave .hp-banner .content__button.border-transparent {
    border: 1px solid #006661;
    color: #006661;
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .hp-banner-container.full-background-wave .hp-banner .content__button.border-transparent:hover {
    background-color: #006078;
    color: #fff;
  }
  .hp-banner-container.full-background-wave .hp-banner .content__button:hover {
    color: #006661;
    background-color: #E8F5F3;
    border: 1px solid #006661;
  }
}
.hp-banner-container .hp-banner {
  padding: 2rem 0;
  position: relative;
  z-index: 1;
}

@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container .hp-banner {
    padding: 1.5rem 0;
    margin: 0 2.5rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .hp-banner-container .hp-banner {
    padding: 1rem 0;
    margin: 0;
  }
}
.hp-banner-container .hp-banner__image img {
  position: absolute;
  left: 60%;
  top: 2rem;
}

@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container .hp-banner__image img {
    position: unset;
    width: 100%;
  }
}
.hp-banner-container .hp-banner .content {
  margin-bottom: 3rem;
}

@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container .hp-banner .content {
    background-color: #fff;
    margin-bottom: 2.5rem;
  }
}
@media print, screen and (max-width: 29.99875em) {
  .hp-banner-container .hp-banner .content {
    background-color: #fff;
    margin-bottom: 2rem;
  }
}
.hp-banner-container .hp-banner .content__title h1 {
  color: #fff;
  font-size: 2.5rem;
  line-height: 3rem;
}

@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container .hp-banner .content__title h1 {
    color: #292c2e;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .hp-banner-container .hp-banner .content__title h1 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #292c2e;
  }
}
.hp-banner-container .hp-banner .content__rte {
  margin: 1rem 0;
}

@media print, screen and (max-width: 39.99875em) {
  .hp-banner-container .hp-banner .content__rte {
    margin: 0.5rem 0 1rem 0;
  }
}
.hp-banner-container .hp-banner .content__rte p {
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: #fff;
}

@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container .hp-banner .content__rte p {
    color: #292c2e;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .hp-banner-container .hp-banner .content__rte p {
    font-size: 1rem;
    line-height: 1.4rem;
    margin: 0.5rem 0;
  }
}
.hp-banner-container .hp-banner .content__button {
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  border: none;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  font-weight: bold;
}

.hp-banner-container .hp-banner .mascot-wave {
  width: 100%;
}

@media print, screen and (max-width: 29.99875em) {
  .hp-banner-container .hp-banner .mascot-wave {
    margin-top: -2.5rem;
  }
}
.hp-banner-container .wave {
  position: absolute;
}

@media screen and (min-width: 48em) {
  .hp-banner-container .wave {
    bottom: 0;
    top: 39%;
    width: 100%;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container .wave {
    display: none;
  }
}
.hp-banner-container .credit-simulator {
  background-color: #fff;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: left;
  position: relative;
  margin: 0;
}

@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container .credit-simulator {
    margin: auto;
    background-color: #fff;
  }
  .hp-banner-container .credit-simulator.white {
    background-color: #EFF4F2;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .hp-banner-container .credit-simulator {
    padding: 1rem;
    width: 100%;
  }
}
.hp-banner-container .credit-simulator .text-header {
  margin: 0;
}

.hp-banner-container .credit-simulator .text-subtitle {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media print, screen and (max-width: 39.99875em) {
  .hp-banner-container .credit-simulator .text-subtitle {
    font-size: 1rem;
    line-height: 1.625rem;
    text-align: center;
  }
}
.hp-banner-container .credit-simulator__footer {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #4B4F54;
  margin: 0;
}

@media print, screen and (max-width: 29.99875em) {
  .hp-banner-container .credit-simulator__footer {
    text-align: center;
  }
}
.hp-banner-container .credit-simulator__footer p {
  margin: 0;
}

.hp-banner-container .credit-simulator__amount {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.hp-banner-container .credit-simulator__amount__text {
  color: #4B4F54;
  padding-top: 0.25rem;
}

.hp-banner-container .credit-simulator__amount__text.error {
  color: #c8102e;
}

@media print, screen and (max-width: 39.99875em) {
  .hp-banner-container .credit-simulator__amount__text {
    text-align: center;
  }
}
.hp-banner-container .credit-simulator__amount input#credit-simulator-value--slider {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
  background-color: #fff;
  outline: 1px solid #6F757C;
  color: #4B4F54;
  border-width: 0;
  border-radius: 0.25rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  width: 100%;
  color: #4B4F54;
  margin: 0;
  height: auto;
}

.hp-banner-container .credit-simulator__amount input#credit-simulator-value--slider::-webkit-input-placeholder {
  color: #4B4F54;
}

.hp-banner-container .credit-simulator__amount input#credit-simulator-value--slider::-moz-placeholder {
  color: #4B4F54;
}

.hp-banner-container .credit-simulator__amount input#credit-simulator-value--slider:-ms-input-placeholder {
  color: #4B4F54;
}

.hp-banner-container .credit-simulator__amount input#credit-simulator-value--slider::-ms-input-placeholder {
  color: #4B4F54;
}

.hp-banner-container .credit-simulator__amount input#credit-simulator-value--slider::placeholder {
  color: #4B4F54;
}

.hp-banner-container .credit-simulator #credit-simulator-button--slider {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 0.75rem 0.25rem;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #cacaca;
  cursor: pointer;
  pointer-events: none;
  text-transform: none;
}

.hp-banner-container .credit-simulator #credit-simulator-button--slider.active {
  background-color: var(--primary-color);
  color: #fff;
  pointer-events: initial;
}

.hp-banner-container .credit-simulator__form {
  gap: 0.5rem;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: 1rem 0 0.5rem 0;
}

@media print, screen and (max-width: 63.99875em) {
  .hp-banner-container .credit-simulator__form {
    gap: 1rem;
  }
}