/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.full-background.green {
  background-color: #e8f5e9;
}

.hp-avis-verifies {
  padding: 2rem 0;
}

.hp-avis-verifies h2 {
  margin-bottom: 2rem;
}

@media print, screen and (max-width: 39.99875em) {
  .hp-avis-verifies > .grid-x {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}
.hp-avis-verifies ul {
  margin: 0;
  margin-bottom: 1rem;
}

.hp-avis-verifies__comment {
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 1.5rem 1rem 1rem 3rem;
  position: relative;
}

.hp-avis-verifies__comment i {
  color: var(--primary-color);
  font-size: 2rem;
  left: 0.5rem;
  position: absolute;
  top: 1.5rem;
}

.hp-avis-verifies__comment .author-wrapper {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.hp-avis-verifies__comment .author-wrapper .author {
  font-weight: bold;
}

.hp-avis-verifies__comment .request,
.hp-avis-verifies__comment .publish {
  color: #8a8a8a;
  font-size: 0.8rem;
  line-height: 0.8rem;
}

.hp-avis-verifies__comment:not(:last-child) {
  margin-bottom: 1rem;
}

.hp-avis-verifies .avisverifies__star-container {
  background-image: url("/content/dam/cetelem/components/hp_info-card-reinsurance/avisverifies-star-empty.svg");
  background-repeat: repeat-x;
  background-size: 26px;
  content: "";
  height: 26px;
  margin-bottom: 0.2rem;
  position: relative;
  width: 130px;
}

.hp-avis-verifies .avisverifies__star-container--rate {
  background-image: url("/content/dam/cetelem/components/hp_info-card-reinsurance/avisverifies-star-full.svg");
  background-repeat: repeat-x;
  background-size: 26px;
  bottom: 0;
  content: "";
  display: block;
  height: 26px;
  position: absolute;
  width: 130px;
}

.hp-avis-verifies .right {
  position: relative;
}

.hp-avis-verifies .right .avis-verifies__grade {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  max-width: 20rem;
  padding: 0.5rem;
}

.hp-avis-verifies .right .avis-verifies__grade--value {
  border-right: 1px solid #d9d9d9;
  padding-right: 0.5rem;
}

.hp-avis-verifies .right .avis-verifies__grade--details {
  padding-left: 0.5rem;
}

.hp-avis-verifies .right .avis-verifies__grade--details .avis-verifies__credentials a {
  text-decoration: underline;
}

.hp-avis-verifies .right .avis-verifies__grade .text-headline {
  font-weight: bold;
}

.hp-avis-verifies .right .avis-verifies__illustration {
  bottom: -2rem;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media print, screen and (max-width: 39.99875em) {
  .hp-avis-verifies .right .avis-verifies__illustration {
    display: none;
  }
}
.hp-avis-verifies blockquote {
  border: 0;
  padding: 0;
}