/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.full-background.green {
  background-color: var(--background-color);
}

.hp-info-card-reinsurance {
  margin: 2rem 0;
  padding: 2rem 0;
}

.hp-info-card-reinsurance__header {
  margin-bottom: 2rem;
}

.hp-info-card-reinsurance__header__maintitle {
  margin-bottom: 1rem;
  text-align: center;
}

@media print, screen and (min-width: 40em) {
  .hp-info-card-reinsurance__header__maintitle {
    margin-bottom: 0;
    text-align: left;
  }
}
.hp-info-card-reinsurance__header__avisverifies {
  padding-right: 0;
  text-align: center;
}

@media screen and (min-width: 48em) {
  .hp-info-card-reinsurance__header__avisverifies {
    padding-right: 5rem;
  }
}
.hp-info-card-reinsurance__header__avisverifies .avisverifies {
  display: inline-block;
  margin: 0 auto;
  position: relative;
}

.hp-info-card-reinsurance__header__avisverifies .avisverifies__grade {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.25rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 0.1rem;
}

.hp-info-card-reinsurance__header__avisverifies .avisverifies__grade--value {
  font-size: 1.5rem;
  font-weight: bold;
}

.hp-info-card-reinsurance__header__avisverifies .avisverifies__star-container {
  background-image: url("/content/dam/cetelem/components/hp_info-card-reinsurance/avisverifies-star-empty.svg");
  background-repeat: repeat-x;
  background-size: 26px;
  content: "";
  height: 26px;
  margin: auto;
  margin-bottom: 0.2rem;
  position: relative;
  width: 130px;
}

.hp-info-card-reinsurance__header__avisverifies .avisverifies__star-container--rate {
  background-image: url("/content/dam/cetelem/components/hp_info-card-reinsurance/avisverifies-star-full.svg");
  background-repeat: repeat-x;
  background-size: 26px;
  bottom: 0;
  content: "";
  display: block;
  height: 26px;
  position: absolute;
  width: 130px;
}

.hp-info-card-reinsurance__header__avisverifies .avisverifies__logo {
  display: block;
  margin: auto;
}

.hp-info-card-reinsurance__header__avisverifies .avisverifies::after {
  background-image: url("/content/dam/cetelem/components/hp_info-card-reinsurance/avis-verifies-credito.webp");
  content: "";
  display: none;
  height: 199px;
  left: calc(100% + 0.5rem);
  position: absolute;
  top: 0;
  width: 113px;
}

@media screen and (min-width: 48em) {
  .hp-info-card-reinsurance__header__avisverifies .avisverifies::after {
    display: block;
  }
}
.hp-info-card-reinsurance__cardbox {
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  padding: 1rem;
  position: relative;
}

.hp-info-card-reinsurance__cardbox--icon {
  position: absolute;
  top: 1.5rem;
}

.hp-info-card-reinsurance__cardbox--icon i {
  background-color: var(--primary-color);
  border-radius: 5px;
  color: #fff;
  font-size: 2rem;
  padding: 0.5rem;
}

.hp-info-card-reinsurance__cardbox--textblock {
  padding-left: 4rem;
}

.hp-info-card-reinsurance__cardbox--textblock .hp-info-card-reinsurance__subtitle {
  color: var(--primary-color);
}

.hp-info-card-reinsurance__cardbox--textblock p {
  margin-bottom: 0;
}