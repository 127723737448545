/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.hp-link-article {
  margin: 2rem 0;
}

.hp-link-article__item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 15px;
  color: #292c2e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  gap: 0.5rem;
  overflow: hidden;
}

.hp-link-article__item:hover {
  color: #292c2e;
  text-decoration: underline;
}

.hp-link-article__item__img {
  width: 35%;
}

@media print, screen and (max-width: 39.99875em) {
  .hp-link-article__item__img {
    width: 20%;
  }
}
.hp-link-article__item__title p {
  margin: 0;
}