/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.hp-slider-container {
  position: relative;
}

.hp-slider-container .full-background.green {
  background-color: var(--background-color);
}

.hp-slider-container :not(.slick-initialized) .hp-slider-slick__slide-wrapper {
  display: none;
}

.hp-slider-container :not(.slick-initialized) .hp-slider-slick__slide {
  margin-bottom: 30px;
}

.hp-slider-container :not(.slick-initialized) .hp-slider-slick__slide-wrapper:first-child {
  display: block;
}

.hp-slider-container .hp-slider {
  padding: 1rem;
  position: relative;
}

@media print, screen and (min-width: 30em) {
  .hp-slider-container .hp-slider {
    padding: 2rem 3rem;
  }
}
.hp-slider-container .hp-slider-slick__slide {
  position: relative;
  width: calc(100% - 25rem);
}

@media print, screen and (max-width: 63.99875em) {
  .hp-slider-container .hp-slider-slick__slide {
    width: 100%;
  }
}
.hp-slider-container .hp-slider-slick__slide--title {
  display: block;
  margin-bottom: 1rem;
}

.hp-slider-container .hp-slider-slick__slide--title, .hp-slider-container .hp-slider-slick__slide--rte {
  max-width: calc(100% - 2rem);
}

@media print, screen and (min-width: 40em) {
  .hp-slider-container .hp-slider-slick__slide--title, .hp-slider-container .hp-slider-slick__slide--rte {
    max-width: calc(100% - 16rem);
  }
}
.hp-slider-container .hp-slider-slick__slide .button, .hp-slider-container .hp-slider-slick__slide--rte {
  margin-bottom: 2rem;
}

.hp-slider-container .hp-slider-slick__slide .button {
  display: none;
}

@media print, screen and (min-width: 40em) {
  .hp-slider-container .hp-slider-slick__slide .button {
    display: inline-block;
  }
}
.hp-slider-container .hp-slider-slick__slide img {
  display: none;
  max-width: 15rem;
  position: absolute;
  right: 0;
  top: 0;
}

@media print, screen and (min-width: 40em) {
  .hp-slider-container .hp-slider-slick__slide img {
    display: block;
  }
}
.hp-slider-container .hp-slider-slick__slide--mobile-button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #292c2e;
  border-radius: 100%;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 2rem;
}

@media print, screen and (min-width: 40em) {
  .hp-slider-container .hp-slider-slick__slide--mobile-button {
    display: none;
  }
}
.hp-slider-container .hp-slider-slick__slide--mobile-button:hover {
  color: #fff;
}

.hp-slider-container .hp-slider .slick-list {
  overflow-x: clip;
  overflow-y: visible;
}

.hp-slider-container .hp-slider .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.hp-slider-container .hp-slider .slick-slide {
  height: inherit;
}

.hp-slider-container .hp-slider .slick-slide > div {
  height: 100%;
}

.hp-slider-container .hp-slider .slick-slide > div .hp-slider-slick__slide-wrapper {
  height: inherit;
}

.hp-slider-container .hp-slider .slick-slide > div .hp-slider-slick__slide-wrapper .hp-slider-slick__slide {
  height: inherit;
  margin-bottom: 0px;
}

.hp-slider-container .hp-slider .slick-autoplay-toggle-button {
  display: none;
}

.hp-slider-container .hp-slider .slick-dots {
  text-align: left;
}

@media print, screen and (max-width: 39.99875em) {
  .hp-slider-container .hp-slider .slick-dots {
    visibility: hidden;
  }
}
.hp-slider-container .hp-slider .slick-dots li button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(1.3rem + 6px);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  width: calc(1.3rem + 6px);
}

.hp-slider-container .hp-slider .slick-dots li button:focus-visible {
  border: 3px dashed var(--primary-color);
}

.hp-slider-container .hp-slider .slick-dots li button .slick-dot-icon {
  border: 2px solid #292c2e;
  border-radius: 100%;
  display: block;
  height: 1.3rem;
  opacity: 1;
  position: relative;
  width: 1.3rem;
}

.hp-slider-container .hp-slider .slick-dots li button .slick-dot-icon::before {
  content: "";
}

.hp-slider-container .hp-slider .slick-dots li.slick-active button .slick-dot-icon {
  background-color: #292c2e;
}

.hp-slider-container .credit-simulator-container {
  position: relative;
}

@media screen and (min-width: 64em) {
  .hp-slider-container .credit-simulator-container {
    left: 50%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
  }
}
.hp-slider-container .credit-simulator {
  background-color: #fff;
  border-radius: 1.5rem;
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  max-width: 24rem;
  padding: 1rem 1.5rem;
  position: absolute;
  right: 1rem;
  text-align: center;
  top: 2rem;
}

@media print, screen and (max-width: 63.99875em) {
  .hp-slider-container .credit-simulator {
    margin: auto;
    max-width: unset;
    position: static;
    top: calc(100% - 3rem);
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem);
    width: 80%;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .hp-slider-container .credit-simulator {
    width: 100%;
  }
}
.hp-slider-container .credit-simulator h2 {
  color: var(--primary-color);
  font-size: 1;
}

.hp-slider-container .credit-simulator label {
  margin-bottom: 0.5rem;
}

.hp-slider-container .credit-simulator .text-small {
  color: #8a8a8a;
  font-size: 0.75rem;
}

.hp-slider-container .credit-simulator__amount {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.hp-slider-container .credit-simulator__amount__input {
  font-weight: bold;
  position: relative;
  width: 100%;
}

.hp-slider-container .credit-simulator__amount__input input {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
  background-color: #f2f3f3;
  border-radius: 0.5rem;
  border-width: 0;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  font-size: 1.5rem;
  font-weight: bold;
  height: unset;
  line-height: unset;
  margin: 0;
  padding: 0.5rem;
  text-align: center;
  width: 100%;
  color: #4B4F54;
}

.hp-slider-container .credit-simulator__amount__input input::-webkit-outer-spin-button, .hp-slider-container .credit-simulator__amount__input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
  margin: 0;
}

.hp-slider-container .credit-simulator__amount__input input:focus-visible {
  outline: unset;
}

.hp-slider-container .credit-simulator__amount__input input:focus {
  background-color: #f2f3f3;
  border-width: 0;
  -webkit-box-shadow: initial;
          box-shadow: initial;
}

.hp-slider-container .credit-simulator__amount__input .euro-symbol {
  font-size: 1.5rem;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.hp-slider-container .credit-simulator__amount__text.error {
  color: #c8102e;
}

.hp-slider-container .credit-simulator__button {
  background-color: #cacaca;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 15px 30px;
  pointer-events: none;
  text-transform: uppercase;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  width: 100%;
}

.hp-slider-container .credit-simulator__button:hover {
  color: #fff;
}

.hp-slider-container .credit-simulator__button.active {
  background-color: var(--primary-color);
  color: #fff;
  pointer-events: initial;
}

.hp-slider-container .credit-simulator__footer {
  margin-bottom: 0;
}